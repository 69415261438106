export default class newSearch {
    constructor(catalogueController) {
        this.category = null;
        this.searchType = null;
        this.catalogueController = catalogueController;

        this.resultsContainer = document.getElementById('results');
        this.perfumesListElement = document.getElementById('perfumesList');
        this.searchInput = document.getElementById('searchInput');

        this.setupEventListeners();
    }

    setupEventListeners() {
        // Handle category selection
        const categoryButtons = document.querySelectorAll('.categoryBtn');
        categoryButtons.forEach(button => {
            button.addEventListener('click', () => {
                this.category = button.getAttribute('data-category');
                document.getElementById('searchSelection').style.display = 'none';
                document.getElementById('searchOptions').style.display = 'block';
            });
        });

        // Handle search type selection
        const searchTypeButtons = document.querySelectorAll('.searchTypeBtn');
        searchTypeButtons.forEach(button => {
            button.addEventListener('click', () => {
                this.searchType = button.getAttribute('data-type');
                document.getElementById('searchOptions').style.display = 'none';
                document.getElementById('searchInterface').style.display = 'block';

                if (this.searchType === 'notes') {
                    this.searchInput.placeholder = 'Enter notes (e.g., vanilla, bergamot)';
                } else {
                    this.searchInput.placeholder = 'Enter a perfume name or brand';
                }
            });
        });

        // Handle the actual search
        document.querySelector('.srcBtn').addEventListener('click', () => {
            const query = this.searchInput.value.trim();
            if (!query) {
                this.displayMessage('Please enter a search term.');
                return;
            }

            this.search(query).then(results => this.displaySearchResults(results));
        });

        // Handle Back buttons
        const backButtons = document.querySelectorAll('.backBtn');
        backButtons.forEach(button => {
            button.addEventListener('click', () => {
                this.handleBackNavigation();
            });
        });

        const mainSearchInput = document.getElementById('mainSearchInput');
        const mainSearchButtons = document.getElementById('mainSearchButtons');
        const mainSearchBtns = document.querySelectorAll('.mainSearchBtn');
        
        // Show buttons when user focuses on the input
        mainSearchInput.addEventListener('focus', () => {
            mainSearchButtons.style.display = 'flex'; // Show search type buttons
        });

        // Handle search initiation from main section
        mainSearchBtns.forEach(button => {
            button.addEventListener('click', () => {
                const searchType = button.getAttribute('data-type'); // Get search type (name or notes)
                const query = mainSearchInput.value.trim(); // Get the search query

                if (!query) {
                    this.displayMessage('Please enter a search term.');
                    return;
                }

                // Set the search type and category
                this.searchType = searchType; // Either 'name' or 'notes'
                this.category = 'all'; // Search both men and women tables
                
                // Fetch results and update the catalogue directly
                this.search(query).then(results => {
                    this.displayResultsInCatalogue(results); // Populate catalogue directly
                });
            });
        });
    }

    handleBackNavigation() {
        const searchSelection = document.getElementById('searchSelection');
        const searchOptions = document.getElementById('searchOptions');
        const searchInterface = document.getElementById('searchInterface');

        if (searchInterface.style.display === 'block') {
            // If on searchInterface, go back to searchOptions
            searchInterface.style.display = 'none';
            searchOptions.style.display = 'block';
        } else if (searchOptions.style.display === 'block') {
            // If on searchOptions, go back to searchSelection
            searchOptions.style.display = 'none';
            searchSelection.style.display = 'block';
        }
    }

    async search(query) {
        try {
            const response = await fetch(
                `searchPerfumesB.php?category=${encodeURIComponent(this.category)}&type=${encodeURIComponent(this.searchType)}&query=${encodeURIComponent(query)}`
            );
            if (!response.ok) {
                throw new Error('Error fetching search results.');
            }
            return await response.json();
        } catch (error) {
            console.error('Error searching:', error);
            this.displayMessage('An error occurred while searching. Please try again later.');
            return [];
        }
    }

    displaySearchResults(results) {
        if (!results || results.length === 0) {
            this.displayMessage('No matches found.');
            return;
        }

        this.navigateToCatalogue(results);
    }

    navigateToCatalogue(results) {
        navigateToSection('catalogue');
        this.perfumesListElement.style.display = 'flex';
        setTimeout(() => {
            this.catalogueController.displayPerfumes(results);
        }, 500);
    }

    displayResultsInCatalogue(results) {
        // Navigate directly to the catalogue section
        navigateToSection('catalogue');
        this.perfumesListElement.style.display = 'flex';

        // Pass the search results to the catalogue controller
        this.catalogueController.displayPerfumes(results);
    }

    displayMessage(message) {
        this.resultsContainer.innerHTML = `<p>${message}</p>`;
        this.resultsContainer.style.display = 'block';
    }
}
